





































































































































import Vue from 'vue';
import moment from 'moment';
import { mapState } from 'vuex';
import _ from 'lodash';
import { ResponseCode } from '~/util/constant';
import { $cookies } from '~/util/api';
import { prefixZero } from '~/util/common';

export default Vue.extend({
  name: 'Booth',
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
    showVideo: {
      type: Boolean,
      default: false,
    },
  },
  data(): any {
    return {
      // 倒计时天数
      days: '--',
      countDown: '',
      fairEnd: false,
      // 搜索关键词
      keyword: '',
      // 是否显示
      isShowSearch: false,
      menu: [
        {
          label: '首页',
          title: '展会网',
          path: '/',
          activePath: '/',
          extra: true,
        },
        {
          label: '国内展会',
          title: '国内展会',
          path: '/exhibition-0-0-1-0-0-0-1/',
          activePath: 'exhibition-(\\d+)-(\\d+)-1',
        },
        {
          label: '国际展会',
          title: '国际展会',
          path: '/exhibition/',
          activePath: /^\/exhibition((-\d+){2}(-[0,2-9]+)(-\d+){4})?\/?/,
        },
        {
          label: '展会资讯',
          title: '展会资讯',
          path: '/information/',
          activePath: '/information',
        },
        {
          label: '会展中心',
          title: '会展中心',
          path: '/pavilion/',
          activePath: /^\/pavilion((-\d+){2}(-[0,2-9]+)(-\d+){4})?\/?/,
        },
        {
          label: '展会合作',
          title: '展会合作',
          path: '/cooperation.html',
          activePath: '/cooperation.html',
        },
        {
          label: '参展企业',
          title: '参展企业',
          path: '/company/',
          activePath: '/company',
          hidden: true,
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.getCountDown();
  },
  computed: {
    ...mapState({
      isLogin: (state: any) => state.global.isLogin,
    }),
  },
  methods: {
    // 登录
    handleLogin() {
      this.$login.show();
    },
    handleToManger() {
      window.open('/manager/', '_blank');
    },
    // 注销登录
    async handleLogout() {
      const res: any = await this.$axios.get('/api/auth/logout');
      if (res.code === ResponseCode.SUCCESS) {
        $cookies.removeAll();
        this.$store.commit('global/setIsLogin', false);
        this.$store.commit('global/setUser', {});
      }
    },
    // 搜索
    handleSearch: _.debounce(
      function () {
        // @ts-ignore
        const { keyword, isLogin, $login } = this;
        if (!isLogin) {
          return $login.show();
        }
        if (keyword) {
          window.open(`/exhibition/?keyword=${keyword}`, '_blank');
        }
      },
      3000,
      { leading: true }
    ),
    // 点击搜索图标显示搜索框
    showInput() {
      this.isShowSearch = true;
    },
    // 获取开展倒计时
    getCountDown() {
      if (process.client && this.detail.dynamic === 0) {
        if (moment() > moment(`${this.detail.endTime}T18:00:00`)) {
          this.countDown = '';
          this.fairEnd = true;
          return false;
        }
        this.timer = setInterval(() => {
          const startTime = moment(); // 开始时间
          const endTime = moment(`${this.detail.endTime}T18:00:00`).subtract(
            1,
            'days'
          ); // 结束时间
          const diff = endTime.diff(startTime); // 时间差
          const arr = [];
          // 格式化为需要的格式 这里是天时分秒
          const days = Math.floor(moment.duration(diff).asDays());
          arr.push(days < 10 ? prefixZero(days, 2) : days);
          arr.push(prefixZero(moment.duration(diff).hours(), 2));
          arr.push(prefixZero(moment.duration(diff).minutes(), 2));
          arr.push(prefixZero(moment.duration(diff).seconds(), 2));
          this.days = String(arr[0]);
          this.countDown = arr.toString().replace(/,/g, '');
        }, 1000);
      }
    },
    async handleToPage(tag: string) {
      const exhibitionId = this.detail.exhibitionId;
      await this.$store.commit(
        'user/setOrigins',
        location.origin + this.$route.fullPath
      );
      await this.$store.commit('user/setIndustryId', this.industryId);

      switch (tag) {
        case '展位预订':
        case '展位':
          await this.$store.commit('user/setAction', 1);
          window.open(`/exhibition/apply/${exhibitionId}.html`, '_self');
          break;
        case '门票预订':
        case '门票':
          // if (this.isNoTicket === 1) {
          //   this.$message.warning('门票已售罄!');
          // } else {
          //   window.open(`/exhibition/ticket/${exhibitionId}.html`, '_blank');
          // }
          await this.$store.commit('user/setAction', 2);
          window.open(`/exhibition/ticket/${exhibitionId}.html`, '_blank');
          break;
        case '展商名录':
        case '会刊':
          await this.$store.commit('user/setAction', 3);
          window.open(`/periodical/${exhibitionId}.html`, '_blank');
          break;
      }
    },
  },
});
